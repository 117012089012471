import { useEffect, useState } from "react";

import {
  disconnectSocketConnection,
  initSocketConnection,
} from "../utils/socketIO";

function Slave() {
  useEffect(() => {
    const newSocket = initSocketConnection("/slave");
    setSocket(newSocket);

    newSocket.on("slave:receive", (data) =>
      setMessages((prev) => [data, ...prev])
    );

    return () => disconnectSocketConnection(newSocket);
  }, []);

  const [socket, setSocket] = useState();

  const [messages, setMessages] = useState([]);

  return (
    <div className="container">
      <h1>Slave Client</h1>

      {messages.length > 0 ? (
        <ul className="list-group">
          {messages.map((message) => (
            <li className="list-group-item">{message}</li>
          ))}
        </ul>
      ) : (
        <h1 className="text-uppercase text-center mt-4">No Data Yet</h1>
      )}
    </div>
  );
}

export default Slave;
