function Home() {
  return (
    <div className="container d-flex justify-content-center">
      <a className="btn btn-primary btn-lg mr-4" href="/master">
        Master Client
      </a>
      <a className="btn btn-primary btn-lg" href="/slave">
        Slave Client
      </a>
    </div>
  );
}

export default Home;
