import { useEffect, useState } from "react";

import {
  disconnectSocketConnection,
  initSocketConnection,
} from "../utils/socketIO";

function Master() {
  useEffect(() => {
    const newSocket = initSocketConnection("/master");
    setSocket(newSocket);

    return () => disconnectSocketConnection(newSocket);
  }, []);

  const [socket, setSocket] = useState();

  const [sendText, setSendText] = useState();

  const sendMessage = () => {
    socket.emit("master:send", sendText);
    setSendText("");
  };

  return (
    <div className="container">
      <h1>Master Client</h1>

      <input
        type="text"
        class="form-control form-control-lg"
        placeholder="Enter data"
        value={sendText}
        onChange={(e) => setSendText(e.target.value)}
      />

      <div className="btn btn-primary w-100 btn-lg mt-3" onClick={sendMessage}>
        Send
      </div>
    </div>
  );
}

export default Master;
