import React from "react";

import Home from "../pages/Home";
import Master from '../pages/Master'
import Slave from '../pages/Slave'

const routes = [
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "master",
    element: <Master/>,
  },
  {
    path: "slave",
    element: <Slave/>,
  },
];

export default routes;
